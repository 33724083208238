import {
  getCurrencySymbol as _getCurrencySymbol,
  priceFormatWithoutCurrency as _priceFormatWithoutCurrency,
  priceFormatWithoutCurrencyAndWithShortener as _priceFormatWithoutCurrencyAndWithShortener
} from '@dante/price-formatters';
import { DEFAULT_CURRENCY } from '@/shared/config';

export const getCurrencySymbol = (currency?: string) =>
  _getCurrencySymbol(DEFAULT_CURRENCY, currency);

export const priceFormatWithoutCurrency = (price: number) =>
  _priceFormatWithoutCurrency(price, DEFAULT_CURRENCY);

export const priceFormatWithoutCurrencyAndWithShortener = (price: number) =>
  _priceFormatWithoutCurrencyAndWithShortener(price, DEFAULT_CURRENCY);
