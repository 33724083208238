'use client';

import React from 'react';
import { Controller } from 'react-hook-form';

import { Profile } from '@/entities/profile/types';
import { Button } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';

import { NewPaymentMethod } from '../../types';
import { DefaultAmountButtonsDesktop } from '../default-amount-buttons/default-amount-buttons-desktop';
import { PaymentAmountInput } from '../payment-amount-input/payment-amount-input';
import { useIMPSWithdrawalForm } from './lib/use-imps-withdrawal-from';
import { IMPSFields } from './imps/imps-fields';

type IMPSWithdrawalFormDesktopProps = {
  selectedMethod: NewPaymentMethod;
  profile: Profile;
  availableForWithdrawal: number;
  withdrawalMin: number;
  withdrawalMax: number;
  onClose: () => void;
};

export const IMPSWithdrawalFormDesktop: React.FC<IMPSWithdrawalFormDesktopProps> = ({
  selectedMethod,
  profile,
  availableForWithdrawal,
  withdrawalMin,
  withdrawalMax,
  onClose
}) => {
  const t = useScopedI18n('wallet.modal');

  const {
    control,
    amount,
    currencySymbol,
    hasPaymentValidationError,
    infoText,
    handleAmountChange,
    proceedPayment,
    loading,
    submitButtonDisabled,
    countryCode,
    setCountryCode,
    wasAmountChangedByButton,
    setWasAmountChangedByButton
  } = useIMPSWithdrawalForm({
    profile,
    availableForWithdrawal,
    withdrawalMin,
    withdrawalMax,
    selectedMethod,
    onSuccess: onClose
  });

  return (
    <div className="flex w-[478px] flex-col">
      <IMPSFields
        selectedMethod={selectedMethod}
        control={control}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
      <div className="mt-[8px] h-[78px]">
        <Controller
          name={'amount'}
          control={control}
          render={({ field }) => (
            <PaymentAmountInput
              noLabel
              name={field.name}
              onChange={(value) => {
                handleAmountChange(value);
                setWasAmountChangedByButton(false);
              }}
              amount={field.value}
              currencySymbol={currencySymbol}
              className={hasPaymentValidationError ? '!border-error' : ''}
              infoText={infoText}
            />
          )}
        />
      </div>
      <DefaultAmountButtonsDesktop
        wasAmountChangedByButton={wasAmountChangedByButton}
        handleAmountChange={handleAmountChange}
        setWasAmountChangedByButton={setWasAmountChangedByButton}
        currencySymbol={currencySymbol}
        amount={amount || ''}
      />
      <div className="mt-3">
        <Button
          size="lg"
          className="w-full"
          onClick={proceedPayment}
          disabled={submitButtonDisabled}
          loading={loading}
          loadingText={t('loading')}
        >
          {t('withdrawButtonText')}
        </Button>
      </div>
    </div>
  );
};
