'use client';

import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { TextInput } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { useDeviceType } from '@/shared/lib/use-device-type';

import { IMPSWithdrawalValues } from '../types';
import { NAMES_REGEX } from '../constants';

type IMPSNamesFieldsProps = {
  control: Control<IMPSWithdrawalValues, any, IMPSWithdrawalValues>,
};

export const IMPSNamesFields: React.FC<IMPSNamesFieldsProps> = ({
  control
}) => {
  const t = useScopedI18n('wallet.modal');
  const device = useDeviceType();

  return (
    <>
      {device === 'desktop' ? (
        <div className="flex h-[78px] w-full gap-[8px]">
          <Controller
            name={'firstName'}
            rules={{
              required: t('errors.required'),
              minLength: { value: 2, message: t('errors.firstName') },
              maxLength: { value: 50, message: t('errors.firstName') },
              validate: (value) => NAMES_REGEX.test(value || '') || t('errors.firstName')
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                placeholder="First Name"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                errorMessage={error?.message}
                onBlur={field.onBlur}
                className="w-full"
              />
            )}
          />
          <Controller
            name={'lastName'}
            rules={{
              required: t('errors.required'),
              minLength: { value: 2, message: t('errors.lastName') },
              maxLength: { value: 50, message: t('errors.lastName') },
              validate: (value) => NAMES_REGEX.test(value || '') || t('errors.lastName')
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                className="w-full"
                placeholder="Last Name"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                errorMessage={error?.message}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
      ) : (
        <>
          <div className="flex h-[60px] w-full">
            <Controller
              name={'firstName'}
              rules={{
                required: t('errors.required'),
                minLength: { value: 2, message: t('errors.firstName') },
                maxLength: { value: 50, message: t('errors.firstName') },
                validate: (value) => NAMES_REGEX.test(value || '') || t('errors.firstName')
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  placeholder="First Name"
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  errorMessage={error?.message}
                  onBlur={field.onBlur}
                  className="w-full"
                />
              )}
            />
          </div>
          <div className="mt-[8px] flex h-[60px] w-full">
            <Controller
              name={'lastName'}
              rules={{
                required: t('errors.required'),
                minLength: { value: 2, message: t('errors.lastName') },
                maxLength: { value: 50, message: t('errors.lastName') },
                validate: (value) => NAMES_REGEX.test(value || '') || t('errors.lastName')
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  className="w-full"
                  placeholder="Last Name"
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  errorMessage={error?.message}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};
