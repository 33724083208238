'use client';

import React from 'react';
import { Controller } from 'react-hook-form';

import { Profile } from '@/entities/profile/types';
import { Button } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';

import { NewPaymentMethod } from '../../types';
import { PaymentAmountInput } from '../payment-amount-input/payment-amount-input';
import { DefaultAmountButtonsMobile } from '../default-amount-buttons/default-amount-buttons-mobile';
import { useIMPSWithdrawalForm } from './lib/use-imps-withdrawal-from';
import { IMPSFields } from './imps/imps-fields';

type IMPSWithdrawalFormMobileProps = {
  selectedMethod: NewPaymentMethod;
  profile: Profile;
  availableForWithdrawal: number;
  withdrawalMin: number;
  withdrawalMax: number;
  handleBack: () => void;
};

export const IMPSWithdrawalFormMobile: React.FC<IMPSWithdrawalFormMobileProps> = ({
  selectedMethod,
  profile,
  availableForWithdrawal,
  withdrawalMin,
  withdrawalMax,
  handleBack
}) => {
  const t = useScopedI18n('wallet.modal');

  const {
    control,
    amount,
    currencySymbol,
    hasPaymentValidationError,
    infoText,
    handleAmountChange,
    proceedPayment,
    loading,
    submitButtonDisabled,
    countryCode,
    setCountryCode,
    wasAmountChangedByButton,
    setWasAmountChangedByButton
  } = useIMPSWithdrawalForm({
    profile,
    availableForWithdrawal,
    withdrawalMin,
    withdrawalMax,
    selectedMethod,
    onSuccess: handleBack
  });

  return (
    <div className="flex flex-col pb-0">
      <IMPSFields
        selectedMethod={selectedMethod}
        control={control}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
      <div className="mt-[8px] h-[60px]">
        <Controller
          name={'amount'}
          control={control}
          render={({ field }) => (
            <PaymentAmountInput
              noLabel
              name={field.name}
              onChange={(value) => {
                handleAmountChange(value);
                setWasAmountChangedByButton(false);
              }}
              amount={field.value}
              currencySymbol={currencySymbol}
              className={hasPaymentValidationError ? '!border-error' : ''}
              infoText={infoText}
            />
          )}
        />
      </div>
      <DefaultAmountButtonsMobile
        wasAmountChangedByButton={wasAmountChangedByButton}
        handleAmountChange={handleAmountChange}
        setWasAmountChangedByButton={setWasAmountChangedByButton}
        currencySymbol={currencySymbol}
        amount={amount || ''}
      />
      <div
        className="sticky bottom-0 left-0 right-0 flex h-[113px] w-full flex-col justify-end"
        style={{
          background: 'linear-gradient(180deg, rgba(50, 51, 56, 0) 0%, #323338 50%)'
        }}
      >
        <Button
          size="lg"
          className="w-full"
          onClick={proceedPayment}
          disabled={submitButtonDisabled}
          loading={loading}
          loadingText={t('loading')}
        >
          {t('withdrawButtonText')}
        </Button>
        <div className="h-[16px] bg-background-card" />
      </div>
    </div>
  );
};
