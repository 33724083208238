'use client';

import { useEffect } from 'react';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';

import { usePayment } from '../lib/use-payment';

export const FailDepositOpenModal: React.FC = () => {
  const isDepositFail = safeLocalStorage.getItem(localStorageConsts.isDepositFail) || '';
  const { openDeposit } = usePayment();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isDepositFail && window) {
        openDeposit();
        safeLocalStorage.removeItem(localStorageConsts.isDepositFail);
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [isDepositFail, openDeposit]);

  return null;
};
