'use client';

import React, { ChangeEvent, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { CrossIcon } from '@/shared/ui';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  errorMessage?: string;
  className?: string;
  infoText?: React.ReactNode;
  placeholder?: string;
  hideErrorMessage?: boolean;
  bottomContent?: React.ReactNode;
  showNumberArrows?: boolean;
  disabled?: boolean;
  onInputStart?: () => void;
  currency?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const PaymentTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      hideErrorMessage,
      name,
      label,
      type = 'text',
      errorMessage,
      infoText,
      className,
      onBlur,
      value,
      onChange,
      onInputStart,
      onFocus,
      onKeyDown,
      bottomContent,
      showNumberArrows = false,
      inputMode,
      autoComplete,
      disabled,
      placeholder,
      currency,
      inputClassName,
      autoFocus
    },
    ref
  ) => {
    const [hasStartedInput, setHasStartedInput] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!hasStartedInput && onInputStart) {
        setHasStartedInput(true);
        onInputStart();
      }
      onChange?.(e);
    };

    const [inputFocused, setInputFocused] = useState(false);
    const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(true);
      onFocus?.(e);
    };
    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(false);
      onBlur?.(e);
    };

    const inputComponent = (
      <div className="relative flex items-center">
        {value && (
          <div
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center px-2 md:px-3"
            onClick={() => onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)}
          >
            <CrossIcon className="h-2 w-2 text-white/70 hover:text-white" />
          </div>
        )}
        <input
          id={id}
          ref={ref}
          name={name}
          type="text"
          className={classNames(
            className,
            'border-1 leading-tight h-4t w-full appearance-none rounded-xxs border-divider-standard bg-input pl-[16px] text-sm text-text-primary selection:bg-input placeholder:text-input-placeholder hover:border-divider-light hover:bg-input focus:border-divider-light focus:bg-input focus:outline-none active:bg-input md:h-[54px] md:rounded-s md:text-xl',
            { '!border-divider-light bg-input outline-none': Boolean(value) },
            { 'border-error': Boolean(errorMessage) },
            { 'hide-arrow': type === 'number' && !showNumberArrows },
            inputClassName
          )}
          onBlur={onInputBlur}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          onFocus={onInputFocus}
          inputMode={inputMode}
          autoComplete={autoComplete}
        />
        {value && currency && (
          <div className="pointer-events-none absolute left-2">
            <span ref={ref} className="invisible whitespace-pre text-sm md:text-xl">
              {value || placeholder}
            </span>
            <span className="absolute top-[51%] ml-1 -translate-y-1/2 transform text-sm text-white md:text-xl">
              {currency}
            </span>
          </div>
        )}
      </div>
    );

    if (type === 'hidden') {
      return <div>{inputComponent}</div>;
    }

    return (
      <div
        className={classNames(
          'mb-[4px] min-h-[60px] font-roboto md:mb-[8px] md:min-h-[112px]',
          className
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className="typography-body-regular mb-[8px] block text-text-primary md:typography-title-regular"
          >
            {label}
          </label>
        )}
        <div className={classNames('relative w-full', { 'pointer-events-none': disabled })}>
          {inputComponent}
        </div>
        {errorMessage && !hideErrorMessage && (
          <div className="mt-[4px] h-2 text-sm text-error md:mt-[8px]">
            {errorMessage && !inputFocused ? errorMessage : ''}
          </div>
        )}
        {infoText}
        {bottomContent}
      </div>
    );
  }
);

PaymentTextInput.displayName = 'PaymentTextInput';
