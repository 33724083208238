'use client';

import { useState } from 'react';

export const usePaymentMethodsSelectMobile = () => {
  const [isSelecting, setSelecting] = useState<boolean>(false);

  const toggleSelectingPaymentMethod = () => {
    setSelecting((prev) => !prev);
  };

  return {
    isSelecting,
    toggleSelectingPaymentMethod
  };
};
