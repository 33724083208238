'use client';

import React from 'react';

import { Divider, Modal } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';

import { useWithdrawal } from '../lib/use-withdrawals';
import { PaymentModalInterface } from '../types';
import { PaymentMethodButtonDesktop } from './payment-methods/payment-method-button-desktop';
import { WITHDRAWAL_METHODS } from './payment-methods/withdrawal-methods';
import { IMPSWithdrawalFormDesktop } from './withdrawal-forms/imps-withdrawal-form-desktop';

export const ModalWithdrawalDesktop: React.FC<PaymentModalInterface> = ({ onClose }) => {
  const t = useScopedI18n('wallet.modal');

  const {
    profile,
    limits,
    methods,
    selectedMethod,
    selectedMethodData,
    setSelectedMethod,
    availableForWithdrawal,
    availableForWithdrawalText
  } = useWithdrawal({ withdrawalMethods: WITHDRAWAL_METHODS });

  return (
    <Modal onClose={onClose} className="max-h-[692px] w-[813px]">
      <p className="typography-headline-2-bold text-text-primary">{t('withdrawTitle')}</p>
      <div className="flex gap-[24px]">
        <div className="mt-3 flex w-[206px] flex-col">
          <div className="flex h-[72px] w-full flex-col gap-[8px] rounded-s bg-background-body/40 p-[16px]">
            <p className="typography-button-small-semibold text-text-primary">
              {availableForWithdrawalText}
            </p>
            <p className="typography-mobile-info text-text-info">{t('availableForWithdrawal')}</p>
          </div>
          <p className="typography-desktop-title-regular mt-[16px] text-text-primary">
            {t('withdrawalMethod')}
          </p>
          <div className="max-h-[505px] overflow-y-auto p-[1px]">
            {methods.map(
              ({ imageSrc, altText, title, width, height, name, disabled }) => (
                <PaymentMethodButtonDesktop
                  key={name}
                  imageSrc={imageSrc}
                  altText={altText}
                  title={title}
                  width={width}
                  height={height}
                  onSelect={() => !disabled && setSelectedMethod(name)}
                  selected={selectedMethod === name}
                  disabled={disabled}
                />
              )
            )}
          </div>
        </div>
        <Divider className="mb-0 mt-3 flex min-h-full !w-[1px] !bg-button-disabled pt-4" />
        {profile && (
          <IMPSWithdrawalFormDesktop
            selectedMethod={selectedMethodData}
            availableForWithdrawal={availableForWithdrawal}
            profile={profile}
            withdrawalMin={limits?.withdrawalMin || 0}
            withdrawalMax={limits?.withdrawalMax || Infinity}
            onClose={onClose}
          />
        )}
      </div>
    </Modal>
  );
};
