import { useQuery } from '@apollo/client';
import { PaymentMethod } from '@/__generated__/federation/graphql';
import { getDepositPaymentMethodsQuery } from '../api';

type Params = {
  provider?: string;
};

export const useDepositPaymentMethods = ({ provider }: Params) => {
  const { loading, error, data } = useQuery(getDepositPaymentMethodsQuery, {
    variables: {
      input: {
        provider
      }
    },
    context: {
      client: 'federated'
    }
  });

  const depositPaymentMethodsResult = data?.getDepositPaymentMethods;
  const success = depositPaymentMethodsResult?.success;
  const depositPaymentMethods: PaymentMethod[] = success ? depositPaymentMethodsResult.items : [];

  return {
    error,
    loading,
    success,
    depositPaymentMethods
  };
};
