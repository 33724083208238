'use client';

import React from 'react';
import { Control } from 'react-hook-form';
import classNames from 'classnames';

import { NewPaymentMethod } from '../../../types';
import { IMPSWithdrawalValues } from '../types';
import { IMPSAccountNumberFields } from './account-number-field-imps';
import { IMPSNamesFields } from './name-fields';
import { IMPSPhoneNumberField } from './phone-number-field';
import { IMPSEmailFields } from './email-field';

type IMPSFieldsProps = {
  selectedMethod: NewPaymentMethod;
  control: Control<IMPSWithdrawalValues, any, IMPSWithdrawalValues>;
  countryCode: string;
  setCountryCode: (value: string) => void;
};

export const IMPSFields: React.FC<IMPSFieldsProps> = ({
  selectedMethod,
  control,
  countryCode,
  setCountryCode
}) => {
  const getField = (name: string) => {
    switch (name) {
      case 'accountNumber':
        return <IMPSAccountNumberFields control={control} />;
      case 'name':
        return <IMPSNamesFields control={control} />;
      case 'phoneNumber':
        return (
          <IMPSPhoneNumberField
            control={control}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        );
      case 'email':
        return <IMPSEmailFields control={control} />;
      default:
        return null;
    }
  };

  return (
    <>
      {selectedMethod.fields?.map((el, index) => (
        <div className={classNames(index === 0 ? 'mt-3' : 'mt-[8px]')} key={el}>
          {getField(el)}
        </div>
      ))}
    </>
  );
};
