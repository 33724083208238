import { Profile } from '@/entities/profile/types';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { NewPaymentMethod } from '../../../types';
import { IMPSWithdrawalValues } from '../types';

type StoredIMPSWithdrawalValues = IMPSWithdrawalValues & {
  profileId: string;
};

export const getDefaultValues = (
  profile: Profile,
  selectedMethod: NewPaymentMethod
): Omit<IMPSWithdrawalValues, 'amount'> => {
  const storedData = safeLocalStorage.getItem<StoredIMPSWithdrawalValues>(selectedMethod.name);
  const impsWithdrawalInfo =
    storedData && storedData?.profileId === profile.id ? storedData : ({} as IMPSWithdrawalValues);

  const {
    firstName = profile?.firstName || '',
    lastName = profile?.lastName || '',
    accountNumber = '',
    ifsc = '',
    phoneNumber = profile?.phone || '',
    email = profile?.email || ''
  } = impsWithdrawalInfo;

  return {
    firstName,
    lastName,
    accountNumber,
    ifsc,
    phoneNumber,
    email
  };
};
