import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useScopedI18n } from '@/shared/locales/client';

import { formatAmountValue } from '../../lib/format-amount-value';
import { PaymentTextInput } from './input';

interface Props {
  amount: string;
  onChange: (amount: string) => void;
  allowDecimals?: boolean;
  currencySymbol?: string;
  hasError?: boolean;
  withdraw?: boolean;
  inputRef?: React.RefObject<HTMLInputElement | null>;
  infoText?: React.ReactNode;
  errorMessage?: string;
  noLabel?: boolean;
  name?: string;
  className?: string;
  hideErrorMessage?: boolean;
}

export const PaymentAmountInput: React.FC<Props> = ({
  amount,
  name,
  onChange,
  allowDecimals = false,
  currencySymbol,
  withdraw,
  inputRef,
  infoText,
  errorMessage,
  noLabel,
  className,
  hideErrorMessage = false
}) => {
  const t = useScopedI18n('wallet.modal');
  const [internalValue, setInternalValue] = useState<string>(() => amount);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const maxLength = 10;
    const numChars = value.replace('.', '').length;

    if (numChars > maxLength) {
      return;
    }

    const formattedValue = formatAmountValue(value, allowDecimals);
    setInternalValue(formattedValue);
    onChange(formattedValue);
  };

  useEffect(() => {
    setInternalValue(amount);
  }, [amount]);

  const label = withdraw ? t('withdrawalAmount') : `${t('Amount')}:`;

  return (
    <PaymentTextInput
      name={name ? name : 'price'}
      type="text"
      inputMode="decimal"
      ref={inputRef}
      id="price"
      currency={currencySymbol}
      placeholder={withdraw ? t('enterAmount') : t('Amount')}
      value={internalValue}
      onChange={handleInputChange}
      className={classNames('!mb-0', className)}
      label={noLabel ? '' : label}
      infoText={infoText}
      errorMessage={errorMessage}
      hideErrorMessage={hideErrorMessage}
    />
  );
};
