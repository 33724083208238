'use client';

import React, { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

import { DepositModalQuery, WithdrawalModalQuery } from '@/shared/routes/config';
import { useManageSearchParams } from '@/shared/lib/hooks/use-search-params-manage';

import type { PaymentModalInterface, PaymentModalMode } from '../types';

type Props = {
  DepositModal: React.ComponentType<PaymentModalInterface>;
  WithdrawModal: React.ComponentType<PaymentModalInterface>;
};

export const PaymentModalController: React.FC<Props> = ({ DepositModal, WithdrawModal }) => {
  const searchParams = useSearchParams();
  const { removeParam } = useManageSearchParams();

  const mode = useMemo<PaymentModalMode | null>(() => {
    if (searchParams.has(DepositModalQuery)) return 'deposit';
    if (searchParams.has(WithdrawalModalQuery)) return 'withdraw';
    return null;
  }, [searchParams]);

  const isOpen = mode !== null;
  const isWithdrawal = mode === 'withdraw';

  const close = () => {
    if (!mode) return;
    removeParam(isWithdrawal ? WithdrawalModalQuery : DepositModalQuery);
  };

  if (!isOpen) return null;

  return (
    <>
      {mode === 'deposit' && <DepositModal onClose={close} />}
      {mode === 'withdraw' && <WithdrawModal onClose={close} withdraw />}
    </>
  );
};
