import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { NewPaymentMethod } from '../../types';

interface Props {
  method?: NewPaymentMethod | null;
  onClick?: () => void;
}

export const SelectedMethodButtonMobile: React.FC<Props> = ({ method, onClick }) => {
  if (!method) return null;

  const { imageSrc, altText, width, height, title } = method;
  return (
    <button
      className={classNames(
        'flex h-[52px] w-full cursor-pointer items-center gap-[8px] bg-background-body px-[16px]',
        'border border-primary',
        'rounded-xs border'
      )}
      onClick={onClick}
    >
      <div className="flex h-[48px] gap-[8px] py-[16px]">
        <Image src={imageSrc} alt={altText} width={width} height={height} />
      </div>
      <div className="flex items-center">
        <p className="typography-mobile-info text-text-info">{title}</p>
      </div>
    </button>
  );
};
