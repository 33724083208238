'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { useScopedI18n } from '@/shared/locales/client';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { Button } from '@/shared/ui';
import { localStorageConsts } from '@/shared/const';

type DepositResultProps = {
  isSuccess?: boolean;
};

export const DepositResult: React.FC<DepositResultProps> = ({ isSuccess = false }) => {
  const t = useScopedI18n('wallet');
  const { push } = useRouter();
  const path = safeLocalStorage.getItem(localStorageConsts.depositTriggerURL) || '/';

  const handleBack = () => {
    if (!isSuccess) {
      safeLocalStorage.setItem(localStorageConsts.isDepositFail, 'true');
    }
    push(path);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleBack();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="mb-[142px] flex flex-col items-center justify-between">
      <div className="mt-[142px] flex items-center">
        {isSuccess ? (
          <Image src="/pay/success-deposit.png" alt="Deposit Success" width={233} height={233} />
        ) : (
          <Image src="/pay/negative-deposit.png" alt="Failed Deposit" width={233} height={233} />
        )}
      </div>
      <div className="w-[800px] gap-[16px] p-[16px] text-center">
        <p className="typography-headline mb-1 mt-2 text-text-primary">
          {isSuccess ? t('success') : t('failed')}
        </p>
        <p className="typography-desktop-title-regular mb-1 mt-2 text-text-primary">
          {t('redirect')}
        </p>
      </div>
      <Button size="lg" className="w-[188px]" onClick={handleBack}>
        {isSuccess ? t('successButtonText') : t('failedButtonText')}
      </Button>
    </div>
  );
};
