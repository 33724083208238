'use client';

import React from 'react';
import { Button } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales';

export const AllPaymentMethodsTextButton = ({ onClick }: { onClick: () => void }) => {
  const t = useScopedI18n('wallet.modal');

  return (
    <Button type="span" variant="text" className="h-[20px]" onClick={onClick}>
      <span className="typography-mobile-system-info text-text-brand underline">
        {t('allPaymentMethods')}
      </span>
    </Button>
  );
};
