'use client';

import { KycUserStatus } from '@dante/kyc-common';
import { useScopedI18n } from '@/shared/locales/client';
import { Button, ModalMobile } from '@/shared/ui';

interface Props {
  onClose: () => void;
  kycStatus?: KycUserStatus;
}

const linksMap = {
  kyc_empty: '/kyc',
  kyc_processing: '/profile',
  kyc_rejected: '/'
};

export const WithdrawDeclinedModalMobile: React.FC<Props> = ({ onClose, kycStatus }) => {
  const t = useScopedI18n('wallet.modal');

  const type =
    kycStatus === KycUserStatus.DENIED
      ? 'kyc_rejected'
      : kycStatus === KycUserStatus.PROCESSING
        ? 'kyc_processing'
        : 'kyc_empty';

  const content = (
    <>
      <p className="typography-body-regular mb-4 text-text-primary">
        {t(`withdrawalRejected.${type}.description`)}
      </p>
      <Button href={linksMap[type]} size="mobile-lg" className="w-full">
        {t(`withdrawalRejected.${type}.submit`)}
      </Button>
    </>
  );

  return (
    <ModalMobile
      title={t(`withdrawalRejected.kyc_empty.title`)}
      onClose={onClose}
      className="w-full"
    >
      {content}
    </ModalMobile>
  );
};
