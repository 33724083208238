import React from 'react';
import classNames from 'classnames';
import { priceFormat } from '@dante/price-formatters';
import { NewButton } from '@/shared/ui';

interface Props {
  wasAmountChangedByButton: boolean;
  handleAmountChange: (value: string) => void;
  setWasAmountChangedByButton: (value: boolean) => void;
  currencySymbol: string;
  amount: string;
}

// TO DO: need to get it from AppConfig
const DEFAULT_AMOUNTS = ['1000', '2500', '5000'];

export const DefaultAmountButtonsMobile: React.FC<Props> = ({
  wasAmountChangedByButton,
  handleAmountChange,
  setWasAmountChangedByButton,
  currencySymbol,
  amount
}) => {
  return (
    <div className="my-[16px] flex justify-between gap-[8px]">
      {DEFAULT_AMOUNTS.map((buttonAmount) => {
        const isAmountChangedByThisButton = buttonAmount === amount && wasAmountChangedByButton;
        return (
          <NewButton
            variant={isAmountChangedByThisButton && wasAmountChangedByButton ? 'primary' : 'border'}
            color="white"
            onClick={() => {
              handleAmountChange(buttonAmount);
              setWasAmountChangedByButton(true);
            }}
            key={buttonAmount}
            size="xs"
            className="w-full"
          >
            <span
              className={classNames(
                'typography-button-small-regular',
                isAmountChangedByThisButton && 'text-text-secondary'
              )}
            >
              {priceFormat(Number(buttonAmount), currencySymbol, 'letter', 0)}
            </span>
          </NewButton>
        );
      })}
    </div>
  );
};
