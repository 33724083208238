import { gql } from '@apollo/client';

export const getUserBalanceQuery = gql`
  query GetUserBalance {
    balance {
      success
      message
      balance
      bonusBalance
      lockedBalance
      bonusLockedBalance
    }
  }
`;

export const getWithdrawalsCountQuery = gql`
  query GetWithdrawalsCount {
    getWithdrawalsCount {
      success
      message
      count
    }
  }
`;
