import { useLazyQuery } from '@apollo/client';

import { getUserBalanceQuery, getWithdrawalsCountQuery } from './api';

export function useGetUserBalance() {
  return useLazyQuery(getUserBalanceQuery, {
    fetchPolicy: 'network-only'
  });
}

export function useGetWithdrawalsTotal() {
  return useLazyQuery(getWithdrawalsCountQuery, {
    fetchPolicy: 'network-only'
  });
}
