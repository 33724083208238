import { useMutation } from '@apollo/client';

import { CreateDepositDocument } from '@/__generated__/federation/graphql';

export function useCreateDeposit() {
  return useMutation(CreateDepositDocument, {
    context: {
      client: 'federated'
    }
  });
}
