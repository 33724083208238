'use client';

import { useEffect, useState } from 'react';

const AVERAGE_MOBILE_HEIGHT = 650;

// This hook need for iOS devices in deposit-mobile
// TO DO: DNT-4638 need to make by css for all devices include iOS
export const useViewportHeightMobile = () => {
  const [viewportHeight, setViewportHeight] = useState(AVERAGE_MOBILE_HEIGHT);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const updateHeight = () => setViewportHeight(window.innerHeight);
    updateHeight();

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return {
    viewportHeight
  };
};
