import { ReadonlyURLSearchParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export function useManageSearchParams() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const setParam = (
    name: string,
    value: string,
    options?: NavigateOptions,
    updatedParams?: ReadonlyURLSearchParams
  ) => {
    const params = new URLSearchParams(updatedParams?.toString() || searchParams?.toString());
    params.set(name, value);
    router.push(pathname + '?' + params.toString(), options);
  };

  const removeParam = (name: string) => {
    const params = new URLSearchParams(searchParams?.toString());
    params.delete(name);
    const paramsString = params.toString();
    router.push(`${pathname}${paramsString ? `?${paramsString}` : ''}`);
  };

  return {
    searchParams,
    setParam,
    removeParam
  };
}
