'use client';

import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { TextInput } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { useDeviceType } from '@/shared/lib/use-device-type';

import { IMPSWithdrawalValues } from '../types';
import { EMAIL_REGEX } from '../constants';

type IMPSNamesFieldsProps = {
  control: Control<IMPSWithdrawalValues, any, IMPSWithdrawalValues>,
};

export const IMPSEmailFields: React.FC<IMPSNamesFieldsProps> = ({
  control
}) => {
  const t = useScopedI18n('wallet.modal');
  const device = useDeviceType();

  return (
   <div className={classNames(
      device === 'desktop' ? "h-[78px]" : "h-[60px]"
    )}>
      <Controller
        name={'email'}
        rules={{
          required: t('errors.required'),
          minLength: { value: 6, message: t('errors.email') },
          maxLength: { value: 256, message: t('errors.email') },
          validate: (value) => EMAIL_REGEX.test(value || '') || t('errors.email')
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextInput
            placeholder="Email"
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            errorMessage={error?.message}
            onBlur={field.onBlur}
            className="w-full"
          />
        )}
      />
    </div>
  );
};
