import React from 'react';
import { priceFormat } from '@dante/price-formatters';
import { NewButton } from '@/shared/ui';

interface Props {
  wasAmountChangedByButton: boolean;
  handleAmountChange: (value: string) => void;
  setWasAmountChangedByButton: (value: boolean) => void;
  currencySymbol: string;
  amount: string;
}

// TO DO: need to get it from AppConfig
const DEFAULT_AMOUNTS = ['1000', '2500', '5000', '10000'];

export const DefaultAmountButtonsDesktop: React.FC<Props> = ({
  wasAmountChangedByButton,
  handleAmountChange,
  setWasAmountChangedByButton,
  currencySymbol,
  amount
}) => {
  return (
    <div className="mt-3 flex justify-between gap-[8px]">
      {DEFAULT_AMOUNTS.map((buttonAmount) => (
        <NewButton
          variant={buttonAmount === amount && wasAmountChangedByButton ? 'primary' : 'border'}
          color="white"
          onClick={() => {
            handleAmountChange(buttonAmount);
            setWasAmountChangedByButton(true);
          }}
          key={buttonAmount}
          size="s"
          className="w-full !rounded-[8px]"
        >
          {priceFormat(Number(buttonAmount), currencySymbol, 'letter', 0)}
        </NewButton>
      ))}
    </div>
  );
};
