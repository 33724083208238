'use client';

import React from 'react';
import { Controller } from 'react-hook-form';

import { Button, Divider, Modal, Spinner } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';

import { useDeposit } from '../lib/use-deposit';
import { PaymentModalInterface } from '../types';
import { PaymentMethodButtonDesktop } from './payment-methods/payment-method-button-desktop';
import { DefaultAmountButtonsDesktop } from './default-amount-buttons/default-amount-buttons-desktop';
import { PaymentAmountInput } from './payment-amount-input/payment-amount-input';

export const ModalDepositDesktop: React.FC<PaymentModalInterface> = ({ onClose }) => {
  const t = useScopedI18n('wallet.modal');

  const {
    control,
    amount,
    currencySymbol,
    infoText,
    selectedMethod,
    setSelectedMethod,
    handleAmountChange,
    proceedPayment,
    hasPaymentValidationError,
    submitButtonDisabled,
    loading,
    wasAmountChangedByButton,
    setWasAmountChangedByButton,
    depositMethods,
    depositPaymentMethodsLoading
  } = useDeposit({ isDesktop: true });

  return (
    <Modal onClose={onClose} className="max-h-[606px] w-[813px]">
      <p className="typography-headline-2-bold text-text-primary">{t('addFundsTitle')}</p>
      <div className="flex gap-[24px] pt-3">
        <div className="flex w-[206px] flex-col">
          <p className="typography-desktop-title-regular text-text-info">{t('depositMethod')}</p>
          {depositPaymentMethodsLoading ? (
            <Spinner />
          ) : (
            <div className="max-h-[488px] overflow-y-auto p-[1px]">
              {depositMethods.map((method) => (
                <PaymentMethodButtonDesktop
                  key={method.name}
                  imageSrc={method.imageSrc || ''}
                  altText={method.altText || 'logo'}
                  title={method.title || 'title'}
                  width={method.width || 0}
                  height={method.height || 0}
                  onSelect={() =>
                    !method.disabled && setSelectedMethod(method.name)
                  }
                  selected={selectedMethod === method.name}
                  disabled={method.disabled}
                />
              ))}
            </div>
          )}
        </div>
        <Divider className="mb-0 flex min-h-full !w-[1px] !bg-button-disabled pt-4" />
        <div className="flex w-[478px] flex-col">
          <div className="h-[2px]" />
          <div className="h-[78px]">
            <Controller
              name={'amount'}
              control={control}
              render={({ field }) => (
                <PaymentAmountInput
                  noLabel
                  name={field.name}
                  onChange={(value) => {
                    handleAmountChange(value);
                    setWasAmountChangedByButton(false);
                  }}
                  amount={field.value}
                  currencySymbol={currencySymbol}
                  className={hasPaymentValidationError ? '!border-error' : ''}
                  infoText={infoText}
                />
              )}
            />
          </div>
          <DefaultAmountButtonsDesktop
            wasAmountChangedByButton={wasAmountChangedByButton}
            handleAmountChange={handleAmountChange}
            setWasAmountChangedByButton={setWasAmountChangedByButton}
            currencySymbol={currencySymbol}
            amount={amount || ''}
          />
          <div className="mt-3">
            <Button
              size="lg"
              className="w-full"
              onClick={proceedPayment}
              disabled={submitButtonDisabled}
              loading={loading}
              loadingText={t('loading')}
            >
              {t('addFundsTitle')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
