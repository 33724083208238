'use client';

import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { useBlacklist } from '@/entities/blacklist';
import { useScopedI18n } from '@/shared/locales/client';
import { useDeviceType } from '@/shared/lib/use-device-type';
import { CustomPhoneInput } from '@/shared/ui/new-phone-input';
import {
  checkCountryInBlacklist,
  countries,
  detectCountryByNumber,
  excludeCountries
} from '@/shared/ui/new-phone-input/countries';
import { IMPSWithdrawalValues } from '../types';

type IMPSPhoneNumberFieldProps = {
  control: Control<IMPSWithdrawalValues, any, IMPSWithdrawalValues>,
  countryCode: string;
  setCountryCode: (value: string) => void;
};

export const IMPSPhoneNumberField: React.FC<IMPSPhoneNumberFieldProps> = ({
  control,
  countryCode,
  setCountryCode
}) => {
  const t = useScopedI18n('wallet.modal');
  const device = useDeviceType();
  const { countries: blacklistCountries } = useBlacklist();

  return (
    <div className={classNames(
      device === 'desktop' ? "h-[78px]" : "h-[60px]"
    )}>
      <Controller
        name={'phoneNumber'}
        control={control}
        rules={{
          required: t('errors.required'),
          pattern: { value: /^[^_]+$/, message: t('errors.phone') },
          validate: (value) => {
            const countryIso = detectCountryByNumber(value).iso;

            if (checkCountryInBlacklist({ countryIso, blacklist: blacklistCountries })) {
              return t('errors.phoneCode');
            }

            if (countryIso === countries[0].iso) {
              return t('errors.phone');
            }

            return true;
          }
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <CustomPhoneInput
              countries={excludeCountries({ countries, excludeCountries: blacklistCountries })}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              value={field.value}
              onChange={field.onChange}
              name={field.name}
              errorMessage={error?.message}
              onBlur={field.onBlur}
            />
          );
        }}
      />
    </div>
  );
};
