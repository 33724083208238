import { gql } from '@apollo/client';

export const createDepositMutation = gql`
  mutation CreateDeposit($input: CreateDepositInput!) {
    createDeposit(input: $input) {
      success
      message
      error {
        message
        stack
      }
      redirectUrl
      transactionId
      externalId
    }
  }
`;

export const getDepositPaymentMethodsQuery = gql`
  query DepositPaymentMethods($input: GetPaymentMethodRequest) {
    getDepositPaymentMethods(input: $input) {
      success
      message
      error {
        message
        stack
      }
      items {
        id
        name
        title
        type
      }
    }
  }
`;
