import { useEffect, useMemo, useState } from 'react';
import { priceFormat } from '@dante/price-formatters';
import { useProfile } from '@/entities/profile';
import { useAppConfig } from '@/entities/app-config';
import { useGetUserBalance } from '@/entities/balance';
import { useDeviceType } from '@/shared/lib/use-device-type';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';
import { NewPaymentMethod } from '../types';

export const useWithdrawal = ({ withdrawalMethods }: { withdrawalMethods: NewPaymentMethod[] }) => {
  const { profile } = useProfile();
  const {
    config: { limits }
  } = useAppConfig();
  const [getBalance, { data: balanceData }] = useGetUserBalance();
  const device = useDeviceType();
  const isDesktop = device === 'desktop';

  const methods = withdrawalMethods.map((method) => {
    return { ...method, width: isDesktop ? 104 : 69, height: isDesktop ? 24 : 16 };
  });

  const withdrawalPreferMethod = safeLocalStorage.getItem(
    localStorageConsts.withdrawalPreferMethod
  );
  const [selectedMethod, setSelectedMethod] = useState<string>(
    withdrawalPreferMethod || methods[0].name
  );
  const selectedMethodData = useMemo(() => {
    return methods.find((el) => el.name === selectedMethod) || withdrawalMethods[0];
  }, [methods, selectedMethod]);

  useEffect(() => {
    if (profile?.id) {
      getBalance();
    }
  }, [getBalance, profile?.id]);

  const balance = balanceData?.balance.balance || 0;
  const lockedBalance = balanceData?.balance.lockedBalance || 0;
  const availableForWithdrawal = (balanceData && Math.max(balance - lockedBalance, 0)) || 0;

  const currencySymbol = profile?.currencyId || '';
  const availableForWithdrawalText = priceFormat(
    availableForWithdrawal,
    currencySymbol,
    'letter',
    availableForWithdrawal % 1 === 0 ? 0 : 2
  );

  return {
    profile,
    limits,
    methods,
    selectedMethod,
    selectedMethodData,
    setSelectedMethod,
    availableForWithdrawal,
    availableForWithdrawalText
  };
};
