import React from 'react';
import classNames from 'classnames';
import Image, { StaticImageData } from 'next/image';

interface Props {
  imageSrc: StaticImageData;
  altText: string;
  title: string;
  width: number;
  height: number;
  onSelect: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export const PaymentMethodButtonDesktop: React.FC<Props> = ({
  imageSrc,
  altText,
  title,
  width,
  height,
  onSelect,
  selected,
  disabled
}) => {
  return (
    <div
      className={classNames(
        'border-1 mt-[8px] flex w-full cursor-pointer flex-col gap-[16px] rounded-s border border-background-body bg-background-body p-[16px]',
        selected ? '!border-primary' : 'hover:border hover:border-divider-light',
        disabled &&
          '!betterhover:hover:bg-grey-lighter !cursor-not-allowed !border-none !bg-button-disabled !text-text-disabled opacity-30'
      )}
      onClick={onSelect}
    >
      <Image src={imageSrc} alt={altText} width={width} height={height} />
      <p className="typography-mobile-info text-text-info">{title}</p>
    </div>
  );
};
