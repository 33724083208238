import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { WithdrawalStatus } from '@/__generated__/types';

import { findWithdrawalsQuery, cancelWithdrawalMutation, createWithdrawMutation } from './api';

export type WithdrawalStatusClient =
  | 'PENDING'
  | 'PROCESSING'
  | 'FAILED'
  | 'CANCELLED'
  | 'REJECTED'
  | 'COMPLETED';

export type WithdrawalData = {
  id: number;
  amount: number;
  paymentMethod: string;
  status: WithdrawalStatusClient;
  createdAt: Date;
};

const WITHDRAWAL_STATUS_MAP: Record<WithdrawalStatus, WithdrawalStatusClient> = {
  PENDING: 'PENDING',
  SENT_TO_PAYMENT_SYSTEM: 'PROCESSING',
  CANCELLED_BY_USER: 'CANCELLED',
  REJECTED_BY_OPERATOR: 'REJECTED',
  REJECTED_BY_PAYMENT_SYSTEM: 'REJECTED',
  COMPLETED: 'COMPLETED',
  APPROVED_FOR_PAYMENT: 'COMPLETED',
  PROCESSING: 'PROCESSING'
};

export function useFindWithdrawals(options?: QueryHookOptions) {
  const { data, refetch, loading } = useQuery<{ getWithdrawals: { items: WithdrawalData[] } }>(
    findWithdrawalsQuery,
    {
      fetchPolicy: 'network-only',
      ...options
    }
  );

  return {
    data:
      data?.getWithdrawals?.items?.map((el) => ({
        ...el,
        status: WITHDRAWAL_STATUS_MAP[el.status as WithdrawalStatus]
      })) || [],
    loading,
    refetch
  };
}

export function useCancelWithdrawal() {
  return useMutation(cancelWithdrawalMutation);
}

export function useWithdrawRequest() {
  return useMutation(createWithdrawMutation);
}
