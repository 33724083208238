'use client';

import React from 'react';
import { NewPaymentMethod } from '../../types';
import { PaymentMethodButtonMobile } from './payment-method-button-mobile';

type PaymentMethodsMobileProps = {
  methods?: NewPaymentMethod[] | null;
  selectedMethod: string;
  setSelectedMethod: (value: string) => void;
  onClose: () => void;
};

export const PaymentMethodsMobile = ({
  methods,
  selectedMethod,
  setSelectedMethod,
  onClose
}: PaymentMethodsMobileProps) => {
  if (!methods) return null;

  const handleSelectMethod = (name: string) => {
    setSelectedMethod(name);
    onClose();
  };
  return (
    <div className="flex flex-col gap-[8px]">
      {methods.map((method) => (
        <PaymentMethodButtonMobile
          method={method}
          key={method.name}
          selectedMethod={selectedMethod}
          onClick={() => handleSelectMethod(method.name)}
        />
      ))}
    </div>
  );
};
