'use client';

import React from 'react';
import { KycUserStatus } from '@dante/kyc-common';
import { ModalWithdrawalDesktop } from '@/features/wallet';
import { useProfile } from '@/entities/profile';
import { WithdrawDeclinedModal } from './modal-withdraw-declined';

export const ModalWithdrawalWithKycCheckDesktop: React.FC<{ onClose: () => void }> = ({
  onClose
}) => {
  const { profile } = useProfile();

  if (profile?.kycStatus !== KycUserStatus.CONFIRMED) {
    return <WithdrawDeclinedModal onClose={onClose} kycStatus={profile?.kycStatus} />;
  }

  return <ModalWithdrawalDesktop onClose={onClose} />;
};
