'use client';

import { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useForm } from 'react-hook-form';

import { useProfile } from '@/entities/profile';
import { useCreateDeposit, useDepositPaymentMethods } from '@/entities/deposit';
import { useAppConfig } from '@/entities/app-config';
import { useScopedI18n } from '@/shared/locales/client';
import { showToast } from '@/shared/lib/toast';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';
import { useDepositAmountValidation } from '../lib/use-deposit-amount-validation';
import { NewPaymentMethod } from '../types';
import { getDepositMethodsLogos } from './get-deposit-method-logos';

type UseDepositProps = {
  isDesktop?: boolean;
};

type ProceedDepositVariables = {
  amount: number;
  currency: string;
  paymentMethod: string;
};

export const useDeposit = ({ isDesktop }: UseDepositProps) => {
  const { profile } = useProfile();
  const currencySymbol = profile?.currencyId || '';
  const pathname = usePathname();
  const t = useScopedI18n('wallet.modal');

  const { loading: depositPaymentMethodsLoading, depositPaymentMethods } = useDepositPaymentMethods(
    {}
  );

  const depositPaymentMethodsLogos = getDepositMethodsLogos(isDesktop || false);
  const mappedDepositPaymentMethods =
    depositPaymentMethods?.map((method) => {
      return { ...method, ...depositPaymentMethodsLogos };
    }) || [];

  const {
    config: { limits }
  } = useAppConfig();

  const {
    control,
    formState: { isValid },
    watch,
    setValue
  } = useForm<{ amount: string }>({
    defaultValues: { amount: '' },
    mode: 'onBlur'
  });

  const { amount } = watch();
  const [amountChanged, setAmountChanged] = useState(false);
  const [wasAmountChangedByButton, setWasAmountChangedByButton] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('');
  useEffect(() => {
    if (!selectedMethod && mappedDepositPaymentMethods.length > 0) {
      setSelectedMethod(mappedDepositPaymentMethods[0].name);
    }
  }, [mappedDepositPaymentMethods, selectedMethod]);

  const selectedMethodData = useMemo(() => {
    return mappedDepositPaymentMethods.find((el) => el.name === selectedMethod);
  }, [mappedDepositPaymentMethods, selectedMethod]);

  const [createDeposit, { loading }] = useCreateDeposit();

  const handleAmountChange = (value: string) => {
    setValue('amount', value);
    setAmountChanged(true);
  };

  const { infoText, minError, maxError, hasPaymentValidationError } = useDepositAmountValidation({
    amount: amount || '',
    amountChanged,
    depositMin: limits?.depositMin || 0,
    depositMax: limits?.depositMax || Infinity,
    currencySymbol
  });

  const proceedPayment = async () => {
    if (!profile || !amount || minError || maxError || !selectedMethod) return;
    try {
      const preparedAmount = Number.parseFloat(amount);
      const input: ProceedDepositVariables = {
        amount: Math.round(preparedAmount * 100),
        currency: currencySymbol,
        paymentMethod: selectedMethod
      };
      const { data } = await createDeposit({ variables: { input } });

      if (!data?.createDeposit?.success) {
        showToast(t('error'), 'error');
      } else if (data?.createDeposit?.redirectUrl) {
        isDesktop && safeLocalStorage.setItem(localStorageConsts.depositTriggerURL, pathname);
        window.location.href = data.createDeposit.redirectUrl;
      }
    } catch (error) {
      showToast(t('error'), 'error');
    }
  };

  return {
    control,
    amount,
    setValue,
    currencySymbol,
    infoText,
    selectedMethod,
    setSelectedMethod,
    handleAmountChange,
    proceedPayment,
    hasPaymentValidationError,
    submitButtonDisabled: !isValid || !amount || hasPaymentValidationError || loading,
    loading,
    wasAmountChangedByButton,
    setWasAmountChangedByButton,
    depositMethods: mappedDepositPaymentMethods as NewPaymentMethod[],
    depositPaymentMethodsLoading,
    selectedMethodData: selectedMethodData as NewPaymentMethod
  };
};
