import React from 'react';
import { priceFormatWithoutCurrency } from '@/shared/lib/price-formatters';

type Props = {
  price: number;
  currency?: string;
  className?: string;
  formatter?: (price: number) => string;
};

export const Balance: React.FC<Props> = ({
  price,
  currency,
  className,
  formatter = priceFormatWithoutCurrency
}) => {
  return (
    <>
      <span>{formatter(price).replace(/,00/, '')}</span>
      <span className={className}>&nbsp;{currency}</span>
    </>
  );
};
