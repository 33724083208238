'use client';

import { Button, Modal } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { KycUserStatus } from '@dante/kyc-common';

interface Props {
  onClose: () => void;
  kycStatus?: KycUserStatus;
}

const linksMap = {
  kyc_empty: '/kyc',
  kyc_processing: '/profile',
  kyc_rejected: '/'
};

export const WithdrawDeclinedModal: React.FC<Props> = ({ onClose, kycStatus }) => {
  const t = useScopedI18n('wallet.modal');

  const type =
    kycStatus === KycUserStatus.DENIED
      ? 'kyc_rejected'
      : kycStatus === KycUserStatus.PROCESSING
        ? 'kyc_processing'
        : 'kyc_empty';

  const content = (
    <div className="w-full max-w-[310px] md:max-w-[510px]">
      <h2 className="typography-desktop-headline-3 mb-2 text-text-primary">
        {t(`withdrawalRejected.kyc_empty.title`)}
      </h2>
      <p className="typography-footnote mb-4 text-text-primary">
        {t(`withdrawalRejected.${type}.description`)}
      </p>
      <Button href={linksMap[type]} size="lg" className="w-full">
        {t(`withdrawalRejected.${type}.submit`)}
      </Button>
    </div>
  );

  return (
    <Modal onClose={onClose} className="w-full md:max-w-[580px]">
      {content}
    </Modal>
  );
};
