'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { useScopedI18n } from '@/shared/locales/client';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { Button } from '@/shared/ui';
import { localStorageConsts } from '@/shared/const';

type DepositResultProps = {
  isSuccess?: boolean;
};

export const DepositResultMobile: React.FC<DepositResultProps> = ({ isSuccess = false }) => {
  const t = useScopedI18n('wallet');
  const { push } = useRouter();
  const path = safeLocalStorage.getItem(localStorageConsts.depositTriggerURL) || '/';

  const handleBack = () => {
    push(isSuccess ? path : '/deposit');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleBack();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-background-black mb-[84px] mt-[56px] w-full px-2 pb-1 pt-2">
      <div className="flex flex-col items-center justify-between">
        <div className="flex items-center">
          {isSuccess ? (
            <Image src="/pay/success-deposit.png" alt="Deposit Success" width={100} height={100} />
          ) : (
            <Image src="/pay/negative-deposit.png" alt="Failed Deposit" width={100} height={100} />
          )}
        </div>
        <div className="mb-[24px] w-[288px] gap-[16px] p-[16px] text-center">
          <p className="typography-mobile-system-headline mb-1 mt-2 text-text-primary">
            {isSuccess ? t('success') : t('failed')}
          </p>
          <p className="typography-mobile-body mb-1 mt-2 text-text-primary">{t('redirect')}</p>
        </div>
        <Button size="lg" onClick={handleBack} className="w-full">
          {isSuccess ? t('successButtonText') : t('failedButtonText')}
        </Button>
      </div>
    </div>
  );
};
