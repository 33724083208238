import { useEffect, useState } from 'react';
import { debounce } from '@/shared/lib/debounce';
import { useScopedI18n } from '@/shared/locales';
import { priceFormat } from '@dante/price-formatters';

type AmountValidationArgs = {
  amount: string;
  amountChanged: boolean;
  depositMin: number;
  depositMax: number;
  currencySymbol: string;
};

type ValidationErrors = {
  minError: boolean;
  maxError: boolean;
  requiredError: boolean;
};

export const useDepositAmountValidation = ({
  amount,
  amountChanged,
  depositMin,
  depositMax,
  currencySymbol
}: AmountValidationArgs) => {
  const t = useScopedI18n('wallet.modal');
  const [errors, setErrors] = useState<ValidationErrors>({
    minError: false,
    maxError: false,
    requiredError: false
  });

  const validateAmount = debounce(() => {
    if (amount !== '.' && amountChanged) {
      const isEmpty = amount === '' || amount === '.' || amount === '0';
      const amountNumber = Number(amount);

      setErrors({
        requiredError: isEmpty,
        minError: depositMin ? amountNumber < depositMin : false,
        maxError: depositMax ? amountNumber > depositMax : false
      });
    }
  }, 500);

  const { minError, maxError, requiredError } = errors;

  const getLimitsText = () => {
    if (requiredError) {
      return <p className="typography-mobile-info mt-[8px] text-error">{t('errors.required')}</p>;
    }
    if (!depositMin && (!depositMax || depositMax === Infinity)) {
      return '';
    }
    const limitMin = depositMin
      ? priceFormat(depositMin, currencySymbol, 'letter', depositMin % 1 === 0 ? 0 : 2)
      : null;

    const limitMax =
      depositMax && depositMax !== Infinity
        ? priceFormat(depositMax, currencySymbol, 'letter', depositMax % 1 === 0 ? 0 : 2)
        : null;

    const getErrorClass = (hasError: boolean) => (hasError ? 'text-error' : 'text-text-info');

    return (
      <p className="typography-mobile-info mt-[8px]">
        <span className={getErrorClass(minError || maxError)}>{t('limits')}</span>&nbsp;
        {limitMin && (
          <span className={getErrorClass(minError)}>{t('limitsDepositMin', { limitMin })}</span>
        )}
        {limitMin && limitMax && <span className="text-text-info"> - </span>}
        {limitMax && (
          <span className={getErrorClass(maxError)}>{t('limitsDepositMax', { limitMax })}</span>
        )}
      </p>
    );
  };

  const infoText = getLimitsText();

  useEffect(() => {
    validateAmount();
  }, [validateAmount]);

  const hasPaymentValidationError = minError || maxError || requiredError;

  return {
    infoText,
    minError,
    maxError,
    requiredError,
    hasPaymentValidationError
  };
};
