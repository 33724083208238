'use client';

import React from 'react';
import { useRouter } from 'next/navigation';

import { useWithAuth } from '@/features/page-protection';
import { ChevronLeftMobile } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { RoutesConfig } from '@/shared/routes/config';

import { useWithdrawal } from '../lib/use-withdrawals';
import { usePaymentMethodsSelectMobile } from '../lib/use-payment-methods-select-mobile.ts';
import { SelectedMethodButtonMobile } from './payment-methods/selected-method-button-mobile';
import { WITHDRAWAL_METHODS } from './payment-methods/withdrawal-methods';
import { IMPSWithdrawalFormMobile } from './withdrawal-forms/imps-withdrawal-form-mobile';
import { PaymentMethodsMobile } from './payment-methods/payment-methods-mobile';
import { AllPaymentMethodsTextButton } from './payment-methods/all-payment-methods-text-button';

export const WithdrawalMobile = () => {
  useWithAuth();
  const { push } = useRouter();
  const t = useScopedI18n('wallet.modal');

  const {
    profile,
    limits,
    methods,
    selectedMethod,
    selectedMethodData,
    setSelectedMethod,
    availableForWithdrawal,
    availableForWithdrawalText
  } = useWithdrawal({ withdrawalMethods: WITHDRAWAL_METHODS });
  console.log('🚀 ~ WithdrawalMobile ~ methods:', methods);

  const { isSelecting, toggleSelectingPaymentMethod } = usePaymentMethodsSelectMobile();

  const handleSetSelectingMethod = () => {
    if (methods.length > 1) {
      toggleSelectingPaymentMethod();
    }
  };

  const handleBack = () => {
    push(RoutesConfig.withdrawals);
  };

  return (
    <div className="bg-background-black mt-[16px] w-full">
      <div className="mb-xs flex flex-col">
        <div className="relative flex justify-center">
          <button
            className="absolute left-0 mt-[4px] flex items-center text-button-brand"
            onClick={handleBack}
          >
            <ChevronLeftMobile />
          </button>
          <h1 className="typography-mobile-headline-2 mb-2 text-white">{t('withdrawTitle')}</h1>
        </div>
        {isSelecting ? (
          <PaymentMethodsMobile
            methods={methods}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            onClose={toggleSelectingPaymentMethod}
          />
        ) : (
          <div className="relative max-h-[calc(100vh-180px)] overflow-x-scroll rounded-s bg-background-card p-[16px] pb-0">
            <div className="flex h-[72px] w-full flex-col gap-[8px] rounded-xs bg-background-body/40 p-[16px]">
              <p className="typography-button-small-semibold text-text-primary">
                {availableForWithdrawalText}
              </p>
              <p className="typography-mobile-info text-text-info">{t('availableForWithdrawal')}</p>
            </div>
            <div className="mb-1 mt-3 flex items-center justify-between">
              <p className="typography-mobile-title-regular leading-[20px] text-text-info">
                {t('withdrawalMethod')}
              </p>
              {methods.length > 1 && (
                <AllPaymentMethodsTextButton onClick={toggleSelectingPaymentMethod} />
              )}
            </div>
            <div className="pb-[8px]">
              {selectedMethodData && (
                <SelectedMethodButtonMobile
                  method={selectedMethodData}
                  onClick={handleSetSelectingMethod}
                />
              )}
            </div>
            {profile && (
              <IMPSWithdrawalFormMobile
                selectedMethod={selectedMethodData}
                availableForWithdrawal={availableForWithdrawal}
                profile={profile}
                withdrawalMin={limits?.withdrawalMin || 0}
                withdrawalMax={limits?.withdrawalMax || Infinity}
                handleBack={handleBack}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
