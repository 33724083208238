import { useCallback, useEffect, useState } from 'react';
import { priceFormat } from '@dante/price-formatters';
import { debounce } from '@/shared/lib/debounce';
import { useScopedI18n } from '@/shared/locales';

type AmountValidationArgs = {
  amount: string;
  amountChanged: boolean;
  withdrawalMin: number;
  withdrawalMax: number;
  currencySymbol: string;
  availableForWithdrawal: number;
};

type ValidationErrors = {
  minError: boolean;
  maxError: boolean;
  requiredError: boolean;
  availableForWithdrawalError: boolean;
};

export const useWithdrawalAmountValidation = ({
  amount,
  amountChanged,
  withdrawalMin,
  withdrawalMax,
  currencySymbol,
  availableForWithdrawal
}: AmountValidationArgs) => {
  const t = useScopedI18n('wallet.modal');
  const [errors, setErrors] = useState<ValidationErrors>({
    minError: false,
    maxError: false,
    requiredError: false,
    availableForWithdrawalError: false
  });

  const validateAmount = useCallback(
    debounce(() => {
      if (amountChanged) {
        const isEmpty = amount === '' || amount === '.' || amount === '0';
        const amountNumber = Number(amount);

        setErrors({
          requiredError: isEmpty,
          minError: !isEmpty && amountNumber < withdrawalMin,
          maxError: !isEmpty && amountNumber > withdrawalMax,
          availableForWithdrawalError: !isEmpty && amountNumber > availableForWithdrawal
        });
      }
    }, 500),
    [amount, amountChanged, withdrawalMax, withdrawalMin]
  );

  const getInfoText = () => {
    if (errors.requiredError) {
      return <p className="typography-mobile-info mt-[8px] text-error">{t('errors.required')}</p>;
    }
    if (errors.availableForWithdrawalError) {
      return (
        <p className="typography-mobile-info mt-[8px] text-error">
          {t('errors.insufficientFunds')}
        </p>
      );
    }
    if (!withdrawalMin && (!withdrawalMax || withdrawalMax === Infinity)) {
      return '';
    }

    const limitMin = withdrawalMin
      ? priceFormat(withdrawalMin, currencySymbol, 'letter', withdrawalMin % 1 === 0 ? 0 : 2)
      : null;

    const limitMax =
      withdrawalMax && withdrawalMax !== Infinity
        ? priceFormat(withdrawalMax, currencySymbol, 'letter', withdrawalMax % 1 === 0 ? 0 : 2)
        : null;

    const getErrorClass = (hasError: boolean) => (hasError ? 'text-error' : 'text-text-info');

    return (
      <p className="typography-mobile-info mt-[8px]">
        <span className={getErrorClass(errors.minError || errors.maxError)}>{t('limits')}</span>
        &nbsp;
        {limitMin && (
          <span className={getErrorClass(errors.minError)}>
            {t('limitsDepositMin', { limitMin })}
          </span>
        )}
        {limitMin && limitMax && <span className="text-text-info"> - </span>}
        {limitMax && (
          <span className={getErrorClass(errors.maxError)}>
            {t('limitsDepositMax', { limitMax })}
          </span>
        )}
      </p>
    );
  };

  const infoText = getInfoText();

  useEffect(() => {
    validateAmount();
  }, [validateAmount]);

  const { minError, maxError, requiredError, availableForWithdrawalError } = errors;

  const hasPaymentValidationError =
    minError || maxError || requiredError || availableForWithdrawalError;

  return {
    minError,
    maxError,
    requiredError,
    availableForWithdrawalError,
    hasPaymentValidationError,
    infoText
  };
};
