'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import { KycUserStatus } from '@dante/kyc-common';
import { WithdrawalMobile } from '@/features/wallet';
import { useProfile } from '@/entities/profile';
import { RoutesConfig } from '@/shared/routes/config';
import { WithdrawDeclinedModalMobile } from './modal-withdraw-declined-mobile';

export const WithdrawalMobileWithKycCheck = () => {
  const { profile } = useProfile();
  const { push } = useRouter();

  const handleBack = () => {
    push(RoutesConfig.withdrawals);
  };

  if (profile?.kycStatus !== KycUserStatus.CONFIRMED) {
    return <WithdrawDeclinedModalMobile kycStatus={profile?.kycStatus} onClose={handleBack} />;
  }

  return <WithdrawalMobile />;
};
