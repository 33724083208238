import UpiLogo from '@/shared/assets/images/upi.png';
import ImpsLogo from '@/shared/assets/images/imps.png';
import { NewPaymentMethod } from '../types';

export function getDepositMethodsLogos(
  isDesktop: boolean
): Omit<NewPaymentMethod, 'name' | 'title'> {
  return {
    imageSrc: UpiLogo,
    altText: 'UpiLogo',
    width: isDesktop ? 68 : 45,
    height: isDesktop ? 24 : 16
  };
}
