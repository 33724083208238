import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { priceFormat } from '@dante/price-formatters';
import { useProfile } from '@/entities/profile';
import { useAppConfig } from '@/entities/app-config';
import { getCurrencySymbol } from '@/shared/lib/price-formatters';
import { NewPaymentMethod } from '../../types';

interface Props {
  method: NewPaymentMethod;
  onClick: () => void;
  isWithdrawal?: boolean;
  selectedMethod: string;
}

export const PaymentMethodButtonMobile: React.FC<Props> = ({
  method,
  onClick,
  isWithdrawal = false,
  selectedMethod
}) => {
  const { profile } = useProfile();
  const {
    config: { limits }
  } = useAppConfig();
  const isSelected = method.name === selectedMethod;
  const { imageSrc, altText, width, height, title, currency } = method;
  const currencyId = currency || profile?.currencyId;
  const currencySymbol = getCurrencySymbol(currency || profile?.currencyId);
  const limitMax = isWithdrawal ? limits?.withdrawalMax : limits?.depositMax;
  const limitMin = isWithdrawal ? limits?.withdrawalMin : limits?.depositMin;
  const limitMinText = limitMin
    ? `${priceFormat(limitMin || 0, currencyId, 'symbol', limitMin % 1 === 0 ? 0 : 2)}`
    : `0 ${currencySymbol}`;
  const limitMaxText = limitMax
    ? `${priceFormat(limitMax || 0, currencyId, 'symbol', limitMax % 1 === 0 ? 0 : 2)}`
    : '∞';

  return (
    <button
      className={classNames(
        'grid h-[64px] w-full cursor-pointer grid-cols-[33%_67%] items-center gap-[8px] border bg-background-card px-[16px]',
        isSelected ? 'border border-primary' : 'border-divider-standard',
        'rounded-xs'
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <Image src={imageSrc} alt={altText} width={width} height={height} />
      </div>
      <div className="flex flex-col items-start justify-center">
        <p className="typography-mobile-info text-text-info">{title}</p>
        {(limitMax || limitMin) && (
          <p className="typography-mobile-info text-text-info">
            {limitMinText}
            &nbsp;–&nbsp;
            {limitMaxText}
          </p>
        )}
      </div>
    </button>
  );
};
