import React from 'react';

import { Button, NewButton } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';

type Props = {
  onClick: () => void;
};

export const DepositButton: React.FC<Props> = ({ onClick }) => {
  const t = useScopedI18n('header');

  return (
    <>
      <div className="flex md:hidden">
        <Button size="sm" onClick={onClick} className="w-[63px]">
          {t('deposit')}
        </Button>
      </div>
      <div className="hidden w-max md:flex">
        <NewButton
          variant="primary"
          onClick={onClick}
          className="flex items-center px-4 text-text-secondary"
        >
          {t('deposit')}
        </NewButton>
      </div>
    </>
  );
};
