export const formatAmountValue = (value: string, allowDecimals: boolean) => {
  let cleanValue = value.replace(',', '.').replace(/[^0-9.]/g, '.');

  if (!allowDecimals) {
    cleanValue = cleanValue.replace(/\./g, '');
  } else {
    const parts = cleanValue.split('.');
    if (parts.length > 2) {
      cleanValue = `${parts[0]}.${parts.slice(1).join('')}`;
    } else if (parts.length === 2 && parts[1].length > 2) {
      cleanValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }
  }

  if (cleanValue.startsWith('0') && cleanValue.length > 1 && !cleanValue.startsWith('0.')) {
    cleanValue = cleanValue.replace(/^0+/, '');
  }

  return cleanValue;
};
