import ImpsLogo from '@/shared/assets/images/imps.png';
import { NewPaymentMethod } from '../../types';

export const WITHDRAWAL_METHODS: NewPaymentMethod[] = [
  {
    imageSrc: ImpsLogo,
    altText: 'ImpsLogo',
    name: 'imps_odeonpay',
    title: 'IMPS OdeonPay',
    fields: ['name', 'accountNumber', 'phoneNumber', 'email']
  },
  {
    imageSrc: ImpsLogo,
    altText: 'ImpsLogo',
    name: 'imps_p2pay',
    title: 'IMPS P2Pay',
    fields: ['name', 'accountNumber', 'phoneNumber', 'email']
  },
  {
    imageSrc: ImpsLogo,
    altText: 'ImpsLogo',
    name: 'imps_winway',
    title: 'IMPS Winway',
    fields: ['name', 'accountNumber', 'phoneNumber', 'email']
  },
  {
    imageSrc: ImpsLogo,
    altText: 'ImpsLogo',
    name: 'imps_fxmb',
    title: 'IMPS FXMB',
    fields: ['name', 'accountNumber', 'phoneNumber', 'email']
  }
];
