'use client';

import { usePathname, useRouter } from 'next/navigation';

import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';
import { RoutesConfig } from '@/shared/routes/config';
import { useDeviceType } from '@/shared/lib/use-device-type';

export const usePayment = () => {
  const pathname = usePathname();
  const { push } = useRouter();
  const device = useDeviceType();
  const isDesktop = device === 'desktop';

  const openDeposit = () => {
    safeLocalStorage.setItem(localStorageConsts.depositTriggerURL, pathname);
    push(isDesktop ? RoutesConfig.deposit : RoutesConfig.depositMobile);
  };

  const openWithdrawal = () => {
    push(isDesktop ? RoutesConfig.withdrawalModal : RoutesConfig.createWithdrawal);
  };

  return { openDeposit, openWithdrawal };
};
