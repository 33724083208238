import { gql } from '@apollo/client';

export const findWithdrawalsQuery = gql`
  query GetWithdrawals {
    getWithdrawals {
      items {
        id
        userId
        amount
        paymentMethod
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const cancelWithdrawalMutation = gql`
  mutation cancelWithdrawal($withdrawalId: Int!) {
    cancelWithdrawal(withdrawalId: $withdrawalId) {
      success
      message
    }
  }
`;

export const createWithdrawMutation = gql`
  mutation createWithdrawal($input: CreateWithdrawalInput!) {
    createWithdrawal(input: $input) {
      success
      message
    }
  }
`;
