import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { TextInput } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { useDeviceType } from '@/shared/lib/use-device-type';

import { IMPSWithdrawalValues } from '../types';
import { IFCS_REGEX } from '../constants';

type IMPSAccountNumberFieldsProps = {
  control: Control<IMPSWithdrawalValues, any, IMPSWithdrawalValues>;
};

export const IMPSAccountNumberFields: React.FC<IMPSAccountNumberFieldsProps> = ({ control }) => {
  const t = useScopedI18n('wallet.modal');
  const device = useDeviceType();

  return (
    <>
      {device === 'desktop' ? (
        <div className="flex h-[78px] w-full gap-[8px]">
          <Controller
            name={'accountNumber'}
            rules={{
              required: t('errors.required')
              // Hidden by DNT-4782
              // minLength: { value: 9, message: t('errors.accountNumber') },
              // maxLength: { value: 18, message: t('errors.accountNumber') },
              // validate: (value) => IFCS_ACCOUNT_NUMBER_REGEX.test(value) || t('errors.accountNumber')
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                placeholder="Account Number"
                name={field.name}
                // Hidden by DNT-4782
                // onChange={(e) => {
                //   const onlyNumbers = e.target.value.replace(/\D/g, '');
                //   field.onChange(onlyNumbers);
                // }}
                onChange={field.onChange}
                value={field.value}
                errorMessage={error?.message}
                onBlur={field.onBlur}
                className="w-full"
              />
            )}
          />
          <Controller
            name={'ifsc'}
            rules={{
              required: t('errors.required'),
              minLength: { value: 11, message: t('errors.IFSC') },
              maxLength: { value: 11, message: t('errors.IFSC') },
              validate: (value) => IFCS_REGEX.test(value) || t('errors.IFSC')
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                className="w-full"
                placeholder="IFSC"
                name={field.name}
                onChange={(e) => {
                  const capitalize = e.target.value.toUpperCase();
                  field.onChange(capitalize);
                }}
                value={field.value}
                errorMessage={error?.message}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
      ) : (
        <>
          <div className="flex h-[60px] w-full">
            <Controller
              name={'accountNumber'}
              rules={{
                required: t('errors.required')
                // Hidden by DNT-4782
                // minLength: { value: 9, message: t('errors.accountNumber') },
                // maxLength: { value: 18, message: t('errors.accountNumber') },
                // validate: (value) => IFCS_ACCOUNT_NUMBER_REGEX.test(value) || t('errors.accountNumber')
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  placeholder="Account Number"
                  name={field.name}
                  // Hidden by DNT-4782
                  // onChange={(e) => {
                  //   const onlyNumbers = e.target.value.replace(/\D/g, '');
                  //   field.onChange(onlyNumbers);
                  // }}
                  onChange={field.onChange}
                  value={field.value}
                  errorMessage={error?.message}
                  onBlur={field.onBlur}
                  className="w-full"
                />
              )}
            />
          </div>
          <div className="mt-[8px] h-[60px]">
            <Controller
              name={'ifsc'}
              rules={{
                required: t('errors.required'),
                minLength: { value: 11, message: t('errors.IFSC') },
                maxLength: { value: 11, message: t('errors.IFSC') },
                validate: (value) => IFCS_REGEX.test(value) || t('errors.IFSC')
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  className="w-full"
                  placeholder="IFSC"
                  name={field.name}
                  onChange={(e) => {
                    const capitalize = e.target.value.toUpperCase();
                    field.onChange(capitalize);
                  }}
                  value={field.value}
                  errorMessage={error?.message}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};
