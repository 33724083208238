import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Profile } from '@/entities/profile/types';
import { useFindWithdrawals, useWithdrawRequest } from '@/entities/withdrawal';
import { useScopedI18n } from '@/shared/locales/client';
import { showToast } from '@/shared/lib/toast';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { localStorageConsts } from '@/shared/const';
import { useWithdrawalAmountValidation } from '../../../lib/use-withdrawal-amount';
import { NewPaymentMethod } from '../../../types';
import { IMPSWithdrawalValues, IMPSWithdrawalVariables } from '../types';
import { getDefaultValues } from './get-default-values';

type UseIMPSWithdrawalFormProps = {
  profile: Profile;
  availableForWithdrawal: number;
  withdrawalMin: number;
  withdrawalMax: number;
  selectedMethod: NewPaymentMethod;
  onSuccess?: () => void;
};

export const useIMPSWithdrawalForm = ({
  profile,
  availableForWithdrawal,
  withdrawalMin,
  withdrawalMax,
  selectedMethod,
  onSuccess
}: UseIMPSWithdrawalFormProps) => {
  const t = useScopedI18n('wallet.modal');
  const currencySymbol = profile?.currencyId || '';

  const {
    control,
    formState: { isValid, defaultValues },
    watch,
    setValue
  } = useForm<IMPSWithdrawalValues>({
    defaultValues: { ...getDefaultValues(profile, selectedMethod), amount: '' },
    mode: 'onBlur',
    shouldUnregister: false
  });
  const formValues = watch();
  const { firstName, lastName, accountNumber, ifsc, phoneNumber, amount, email } = formValues;

  const [amountChanged, setAmountChanged] = useState<boolean>(false);
  const [wasAmountChangedByButton, setWasAmountChangedByButton] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  const handleAmountChange = (amount: string) => {
    setValue('amount', amount);
    setAmountChanged(true);
  };

  const { hasPaymentValidationError, infoText } = useWithdrawalAmountValidation({
    amount: amount || '',
    amountChanged,
    withdrawalMin,
    withdrawalMax,
    currencySymbol,
    availableForWithdrawal
  });

  const [withdrawRequest, { loading }] = useWithdrawRequest();
  const { refetch } = useFindWithdrawals();

  const proceedPayment = async () => {
    if (
      !profile ||
      !amount ||
      !firstName ||
      !lastName ||
      !accountNumber ||
      !ifsc ||
      !phoneNumber ||
      !email ||
      hasPaymentValidationError
    )
      return;

    try {
      safeLocalStorage.setItem(localStorageConsts.withdrawalPreferMethod, selectedMethod.name);
      safeLocalStorage.setItem(selectedMethod.name, {
        profileId: profile.id,
        firstName,
        lastName,
        accountNumber,
        ifsc,
        phoneNumber,
        email
      });
      const preparedAmount = Number.parseFloat(amount);

      const input: IMPSWithdrawalVariables = {
        amount: Math.round(preparedAmount * 100),
        paymentMethod: selectedMethod.name,
        currency: currencySymbol,
        account: {
          firstName,
          lastName,
          accountNumber,
          ifsc,
          phoneNumber,
          email
        }
      };

      const { data } = await withdrawRequest({ variables: { input } });

      if (!data?.createWithdrawal?.success) {
        showToast(t('error'), 'error');
      } else {
        showToast(t('withdrawalSuccess'), 'success');
        onSuccess?.();
        refetch();
      }
    } catch (error) {
      showToast(t('error'), 'error');
    }
  };

  const submitButtonDisabled = !isValid || !amount || hasPaymentValidationError || loading;

  return {
    control,
    amount,
    setValue,
    setAmountChanged,
    currencySymbol,
    hasPaymentValidationError,
    infoText,
    handleAmountChange,
    proceedPayment,
    loading,
    submitButtonDisabled,
    countryCode,
    setCountryCode,
    wasAmountChangedByButton,
    setWasAmountChangedByButton
  };
};
