'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import { Controller } from 'react-hook-form';

import { useWithAuth } from '@/features/page-protection';
import { Button, ChevronLeftMobile, Spinner } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { localStorageConsts } from '@/shared/const';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

import { useDeposit } from '../lib/use-deposit';
import { usePaymentMethodsSelectMobile } from '../lib/use-payment-methods-select-mobile.ts';
import { useViewportHeightMobile } from '../lib/use-viewport-height-mobile';
import { DefaultAmountButtonsMobile } from './default-amount-buttons/default-amount-buttons-mobile';
import { PaymentAmountInput } from './payment-amount-input/payment-amount-input';
import { PaymentMethodsMobile } from './payment-methods/payment-methods-mobile';
import { SelectedMethodButtonMobile } from './payment-methods/selected-method-button-mobile';
import { AllPaymentMethodsTextButton } from './payment-methods/all-payment-methods-text-button';

export const DepositMobile = () => {
  useWithAuth();
  const { push } = useRouter();
  const path = safeLocalStorage.getItem(localStorageConsts.depositTriggerURL) || '/';

  const handleBack = () => {
    push(path);
  };

  const t = useScopedI18n('wallet.modal');
  const {
    control,
    amount,
    currencySymbol,
    infoText,
    selectedMethod,
    setSelectedMethod,
    handleAmountChange,
    proceedPayment,
    hasPaymentValidationError,
    submitButtonDisabled,
    loading,
    wasAmountChangedByButton,
    setWasAmountChangedByButton,
    depositMethods,
    depositPaymentMethodsLoading,
    selectedMethodData
  } = useDeposit({});

  const { isSelecting, toggleSelectingPaymentMethod } = usePaymentMethodsSelectMobile();

  const handleSetSelectingMethod = () => {
    if (depositMethods.length > 1) {
      toggleSelectingPaymentMethod();
    }
  };

  const { viewportHeight } = useViewportHeightMobile();

  return (
    <div className="bg-background-black mb-[64px] mt-[16px] w-full px-2 pb-1 pt-2">
      <div className="mb-xs flex flex-col">
        <div className="relative flex justify-center">
          <button
            className="absolute left-0 mt-[4px] flex items-center text-button-brand"
            onClick={handleBack}
          >
            <ChevronLeftMobile />
          </button>
          <h1 className="typography-mobile-headline-2 mb-2 text-white">{t('addFundsTitle')}</h1>
        </div>

        {isSelecting ? (
          <PaymentMethodsMobile
            methods={depositMethods}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            onClose={toggleSelectingPaymentMethod}
          />
        ) : (
          <div
            className="relative flex flex-col rounded-s bg-background-card p-[16px] pb-0"
            // TO DO: DNT-4638 need to make by css for all devices include iOS
            style={{ minHeight: `calc(${viewportHeight}px - 180px)` }}
          >
            <div className="flex items-end justify-between p-0">
              <p className="typography-mobile-title-regular leading-[20px] text-text-info">
                {t('depositMethod')}
              </p>
              {depositMethods.length > 1 && (
                <AllPaymentMethodsTextButton onClick={toggleSelectingPaymentMethod} />
              )}
            </div>
            {depositPaymentMethodsLoading ? (
              <Spinner />
            ) : (
              <div className="pb-[16px] pt-1">
                {selectedMethodData && (
                  <SelectedMethodButtonMobile
                    method={selectedMethodData}
                    onClick={handleSetSelectingMethod}
                  />
                )}
              </div>
            )}

            <div className="mt-1 flex h-[60px] w-full">
              <Controller
                name={'amount'}
                control={control}
                render={({ field }) => (
                  <PaymentAmountInput
                    noLabel
                    name={field.name}
                    onChange={(value) => {
                      handleAmountChange(value);
                      setWasAmountChangedByButton(false);
                    }}
                    amount={field.value}
                    currencySymbol={currencySymbol}
                    className={hasPaymentValidationError ? 'w-full !border-error' : 'w-full'}
                    infoText={infoText}
                  />
                )}
              />
            </div>
            <DefaultAmountButtonsMobile
              wasAmountChangedByButton={wasAmountChangedByButton}
              handleAmountChange={handleAmountChange}
              setWasAmountChangedByButton={setWasAmountChangedByButton}
              currencySymbol={currencySymbol}
              amount={amount || ''}
            />
            <Button
              size="lg"
              className="mb-[16px] mt-auto w-full"
              onClick={proceedPayment}
              disabled={submitButtonDisabled}
              loading={loading}
              loadingText={t('loading')}
            >
              {t('addFundsTitle')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
